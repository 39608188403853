<template>
	<div class="showDomesticFlight-box intFlight-box">
		<div class="container" style="min-height: 800px;">
			<div class="search-list-box">
				<intFlightSearch @bindSearchFlight="bindSearchFlight" :showcity="showCity"
					:TicketSearchInfo="TicketSearchInfo" @delete="clickDelete">
				</intFlightSearch>
			</div>
			<div class="notice pd10 gray0" style="" id="box_yiqinggonggao" v-if="GetGongGaoInfo!=''">
				<div class="ofh">
					<div class="fl"><span class="iconfont icon-zhongyaotishi icon-notice">紧急公告：</span></div>
					<div class="fl ft14 " style="margin-top: 3px;">
						{{GetGongGaoInfo.Title}}
						<a href="javascript:;" @click="showGongGao=true" class="ml0">查看详情</a>
					</div>
				</div>
			</div>

			<!--预订的航班-->
			<div class="intl-selectbox" v-if="BookFlightRouting!=null&&BookFlightRouting.length>0">
				<el-row type="flex" class="list" align="middle" v-for="(item, index) in BookFlightRouting" :key="index">
					<div class="leg" v-if="TicketSearchInfo.Dual==2">第{{ index + 1 }} 段</div>
					<div class="leg" v-else>{{item.SegRef==1?'去程':'回程'}}</div>
					<div class="air">
						<img :src="item.AirlineImg" style="width:20px;position:relative;top:2px;" />
						{{ item.MarktingAirlineName }}
						{{ item.trips[0].FlightNO }}
					</div>
					<div class="flex-start infobox">
						<div class="flex-start">
							<div class="airport text-right">{{ item.trips[0].DepartureName }}</div>
							<div class="pl10 pr10">→</div>
							<div class="airport">{{ item.trips[item.trips.length - 1].ArriveName }}</div>
						</div>
						<div class="time">出发：{{ getDataStr(item.trips[0].DepartureDate,"MM-dd") }}
							{{ item.trips[0].DepartureTime }}
						</div>
						<div class="time">到达：{{ getDataStr(item.trips[item.trips.length - 1].ArriveDate,"MM-dd") }}
							{{ item.trips[item.trips.length - 1].ArriveTime }}
						</div>
					</div>
					<div class="translate">{{item.TurnaroundCount==0?'直飞':'转机'+item.TurnaroundCount+'次'}}</div>
					<a class="revise reset-btn">修改航班</a>
				</el-row>
			</div>

			<el-row type="flex" justify="space-between" :gutter="24">
				<!--筛选-->
				<el-col :span="5">
					<el-row type="flex" justify="space-between" class="info-titbox">
						<div><span class="tit">筛选条件</span></div>
						<div><span class="clearbtn" @click="bindResetFilter()">清除全部</span></div>
					</el-row>
					<div class="intl-fliterbox" v-if="FilterFlightInfo!=null">
						<div class="listbox" v-if="FilterFlightInfo.filterAirines != null">
							<div class="head mb10">航空公司</div>
							<el-checkbox-group v-model="checkfilterAirines" @change="bindSetFilter">
								<div v-for="(item, index) in FilterFlightInfo.filterAirines" :key="index">
									<el-checkbox :label="item.filterInfo.Code">
										<el-row type="flex" align="middle">
											<img :src="item.filterInfo.imgurl" />
											<span>{{ item.filterInfo.Name }}</span>
										</el-row>
									</el-checkbox>
								</div>
							</el-checkbox-group>
						</div>
						<div class="listbox" v-if="FilterFlightInfo.filterDeparturedates != null">
							<div class="head mb10">起飞时间</div>
							<el-checkbox-group v-model="checkfilterDeparturedates" @change="bindSetFilter">
								<div v-for="(item, index) in FilterFlightInfo.filterDeparturedates" :key="index">
									<el-checkbox :label="item.filterInfo.Code">{{item.filterInfo.Name}}</el-checkbox>
								</div>
							</el-checkbox-group>
						</div>
						<div class="listbox" v-if="FilterFlightInfo.filteArrivaldates != null">
							<div class="head mb10">降落时间</div>
							<el-checkbox-group v-model="checkfilteArrivaldates" @change="bindSetFilter">
								<div v-for="(item, index) in FilterFlightInfo.filteArrivaldates" :key="index">
									<el-checkbox :label="item.filterInfo.Code">{{item.filterInfo.Name}}</el-checkbox>
								</div>
							</el-checkbox-group>
						</div>
						<div class="listbox" v-if="FilterFlightInfo.filterTransferCitys != null">
							<div class="head mb10">中转城市</div>
							<el-checkbox-group v-model="checkfilterTransferCitys" @change="bindSetFilter">
								<div v-for="(item, index) in FilterFlightInfo.filterTransferCitys" :key="index">
									<el-checkbox :label="item.filterInfo.Code">{{item.filterInfo.Name}}
									</el-checkbox>
								</div>
							</el-checkbox-group>
						</div>
						<block v-if="showAll">
							<!-- <div class="listbox">
								<div class="head mb10">起飞机场</div>
								<el-checkbox-group v-model="checkList">
									<div>
										<el-checkbox label="00:00-06:00"></el-checkbox>
									</div>
								</el-checkbox-group>
							</div>
							<div class="listbox">
								<div class="head mb10">到达机场</div>
								<el-checkbox-group v-model="checkList">
									<div>
										<el-checkbox label="00:00-06:00"></el-checkbox>
									</div>
								</el-checkbox-group>
							</div> -->
						</block>

						<div class="togglebox" v-show="false">
							<div class="toggle-txt" v-if="showAll == false">起飞机场、到达机场、机型、中转城市</div>
							<!--显示全部,"intl-fliterbox"添加"in"-->
							<a class="toggle">
								<span @click="hide()" v-if="showAll == true">
									收起全部
									<i class="icon-arrow iconfont icon-chalv-xiangshang ft12 red"></i>
								</span>
								<span @click="show()" v-if="showAll == false">
									显示全部
									<i class="icon-arrow iconfont icon-chalv-xiangxia ft12 red"></i>
								</span>
							</a>
						</div>
					</div>
				</el-col>
				<!--航班列表-->
				<el-col :span="19">
					<!-- <div class="adv-inlFlight">
						<div class="adv-title" style="border-bottom:0px;">Hide matrix</div>
						<div class="adv-position">
							<superslide :options="options2" style="display: flex;">
								<div class="adv-xiangzuo prev" slot="prev"><span class="iconfont icon-xiangzuo"></span></div>
								<div class="adv-title-box">
									<div class="adv-title"></div>
									<div class="adv-title1">All flights</div>
									<div class="adv-title2">Nonstops</div>
									<div class="adv-title3">1 stops</div>
								</div>

								<div class="adv-title-r">
									<div class="bd" solt="mainCell">
										<ul class="adv-title-right-box">
											<li class="adv-title-right" v-for="(item,i) in [1,2,3,4,5,6,7,8,9,10]" :key="i">
												<div class="adv-title adv-title10">LOT</div>
												<div class="adv-title1">
													<img src="https://img5.yiqifei.com/CX.png!35x35" class="adv-img"/>
												</div>
												<div class="adv-title2">LOT</div>
												<div class="adv-title3 adv-title3-active">LOT</div>
											</li>
										</ul>
									</div>
								</div>
								<div class="hd" slot="titCell" style="display: none;">
									<ul>
										<li>1</li>

									</ul>
								</div>
								<div class="adv-xiangyou next"  slot="next">
									<span class="iconfont icon-xiangyou"></span>
								</div>
							</superslide>
						</div>

					</div> -->

					<el-row type="flex" justify="space-between" align="middle">
						<div class="info-titbox">

							<span class="tit" v-if="TicketSearchInfo.Dual==2">选择第{{ SegRef }}段：</span>
							<span class="tit" v-else>选择{{SegRef==1?'去程':'回程'}}</span>
							<span>{{ TicketSearchInfo.MultSegments[SegRef - 1].DepartInputVal }} --
								{{ TicketSearchInfo.MultSegments[SegRef - 1].ArrivalInputVal }}</span>
							<span class="ml10">{{ TicketSearchInfo.MultSegments[SegRef - 1].DepartDateStr }}</span>
							<!-- <span>（共0个航班）</span> -->
							<span class="subtitle" v-if="isDoneData">
								(已查询到
								<span>{{ searchFlightCount }}</span>
								个低价行程)
							</span>
						</div>
						<div class="gray9">所有航班起飞/降落时间均为当地时间</div>
					</el-row>
					<div class="head-box">
						<div class="load-animation" v-if="isShowFlightLoading">
							<div class="line"></div>
							<img class="animation" src="../../assets/slider.png" />
						</div>
						<el-row type="flex" justify="space-between">
							<div>
								<div class="sorts-box clearfix" v-if="FilterFlightInfo!=null">
									<a :class="'sort-item mr10 '+(filterIndex==0?'active ':'')"
										@click="bindSorter(0,'')"><span>默认排序</span></a>
									<!--选中添加"active",默认是升序，如果是将序，添加"down"-->
									<a :class="'sort-item  mr10 '+(filterIndex==1?'active ':'')+(FilterFlightInfo.Sorter.Ascending?' down':' up')"
										@click="bindSorter(1,'DepartureDate')">
										<span>出发时间</span>
										<i class="sort"></i>
									</a>
									<a :class="'sort-item mr10 '+(filterIndex==2?'active ':'')+(FilterFlightInfo.Sorter.Ascending?' down':' up')"
										@click="bindSorter(2,'ArriveDate')">
										<span>到达时间</span>
										<i class="sort"></i>
									</a>
									<a :class="'sort-item mr10 '+(filterIndex==3?'active ':'')+(FilterFlightInfo.Sorter.Ascending?' down':' up')"
										@click="bindSorter(3,'TotalTime')">
										<span>航程时长</span>
										<i class="sort"></i>
									</a>
									<a :class="'sort-item mr10 '+(filterIndex==4?'active ':'')+(FilterFlightInfo.Sorter.Ascending?' down':' up')"
										@click="bindSorter(4,'AdultPrice')">
										<span>价格</span>
										<i class="sort"></i>
									</a>
								</div>
							</div>
							<el-row type="flex">
								<div class="typebox clearfix">
									<!--当前的添加class"current"-->
									<a :class="(SortType==1?'current':'')" @click="changeSortType(1)">低价优先</a>
									<a :class="(SortType==2?'current':'')" @click="changeSortType(2)">直飞优先</a>
								</div>
								<div class="taxbox ml15 clearfix" @click="bindChageShowTax">
									<!--当前的添加class"current"-->
									<a data-showtax="1" :class="taxchecked ? '' : 'current'">不含税</a>
									<a data-showtax="0" :class="taxchecked ? 'current' : ''">含税</a>
								</div>
							</el-row>
						</el-row>
					</div>

					<block v-for="(item, index) in FlightRouting" :key="index">
						<div class="item-b">
							<el-row type="flex" align="middle" justify="space-between" class="item-i">
								<div class="airbox">
									<div class="air">
										<img :src="'https://img5.yiqifei.com/' + item.MarktingAirline + '.png!35x35'" />
										<span>{{ item.MarktingAirlineName }}</span>
									</div>
									<div>航班号：{{ item.trips[0].FlightNO }}</div>
									<div>机型：{{ item.trips[0].AirplaneKind }}</div>
								</div>
								<el-row type="flex">
									<div class="timebox tr">
										<div class="time">{{ item.trips[0].DepartureTime }}</div>
										<div class="airport ft12">{{ item.trips[0].DepartureName }}
											{{ item.trips[0].DepartureTerminal }}
										</div>
									</div>
									<div class="translatebox">
										<i
											class="change-num">{{item.TurnaroundCount==0?'直飞':'转机'+item.TurnaroundCount+'次'}}</i>
										<em class="change-sym" style="width:70px;"></em>
										<i class="change-city"
											v-if="item.TurnaroundCity != ''">{{ item.TurnaroundCity }}</i>
									</div>
									<div class="timebox">
										<div class="time">
											{{ item.trips[item.trips.length - 1].ArriveTime }}
											<em>{{ item.days }}</em>
										</div>
										<div class="airport ft12">{{ item.trips[item.trips.length - 1].ArriveName }}
											{{ item.trips[item.trips.length - 1].ArrivalTerminal }}
										</div>
									</div>
								</el-row>
								<div class="durationbox">
									<span class="gray6">总时长:</span>
									{{ item.TotalLishi }}
									<div v-if="item.CommendDetail != null && item.CommendDetail != ''">
										<el-dropdown>
											<div><img src="../../assets/youhui.png" class="service-houji" style="" />
											</div>
											<el-dropdown-menu slot="dropdown" style="width:220px;">
												<el-dropdown-item>{{ item.CommendDetail }}</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</div>

								<div class="pricebox">
									<div class="price">
										¥
										<em class="ft20">{{ taxchecked ? item.TotalPrice : item.AdultPrice }}</em>
									</div>
									<span v-if="taxchecked">全程含税价</span>
									<span v-else class="tax ft14 gray9">
										税:
										<em class="ft12">¥</em>
										{{ item.AdultTax }}
									</span>

									<!-- <div class="price">¥<em class="ft20">2307</em></div> -->
								</div>

								<div class="btnbox">
									<el-button class="select-btn selectflight" type="danger" @click="bindItemNext"
										:data-id="item.ID">选择</el-button>
									<div class="show-detail" @click="bindItemClick" :data-id="item.ID"
										:data-idx="index">
										航班详情
										<i class="iconfont icon-arrow icon-xiala" v-if="item.ID != currentItem"></i>
										<i class="iconfont icon-arrow icon-xiala-copy" v-else></i>
									</div>
								</div>
							</el-row>
							<div class="item-detail" v-if="item.ID == currentItem">
								<block v-for="(item_trip, index2) in item.trips" :key="index2">
									<el-row type="flex" class="detail-list" :gutter="24" align="middle">
										<el-col :span="2">
											<div class="titbox">
												<div class="ft14 red">起飞</div>
												<div class="ft14 red">抵达</div>
											</div>
										</el-col>
										<el-col :span="4">
											<div class="timebox">
												<div class="ft16 fb">{{ item_trip.DepartureTime }}</div>
												<div class="ft16 fb">{{ item_trip.ArriveTime }}{{ item_trip.days }}
												</div>
											</div>
										</el-col>
										<el-col :span="4">
											<div class="datebox">
												<div class="ft14 gray6">{{getDataStr(item_trip.DepartureDate,'MM月dd日')}}
													{{getDataStr(item_trip.DepartureDate,'WWW')}}
												</div>
												<div class="ft14 gray6">
													{{getDataStr(item_trip.ArriveDate,'MM月dd日')}}
													{{getDataStr(item_trip.ArriveDate,'WWW')}}
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="airportbox">
												<div class="ft14 gray6">
													<em>
														{{ item_trip.DepartureName }}（{{ item_trip.DepartureCode }}）
														<i class="terminal">{{ item_trip.DepartureTerminal }}</i>
													</em>
												</div>
												<div class="ft14 gray6">
													<em>
														{{ item_trip.ArriveName }}（{{ item_trip.ArriveCode }}）
														<i class="terminal">{{ item_trip.ArrivalTerminal }}</i>
													</em>
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="airbox" style="padding-left:0px;">
												<div class="airblock">
													<div class="clearfix">
														<img :src="item_trip.AirlineImg"
															style="width:15px;height:15px;position:static" />
														{{ item_trip.AirlineName }} {{ item_trip.FlightNO }}
														{{ item_trip.AirplaneKind }}
													</div>
													<div class="ft14 gray6">
														<i class="iconfont icon-chalv-shijian icon-time"></i>
														{{ item_trip.Lishi }}
													</div>
												</div>
											</div>
										</el-col>
									</el-row>
									<el-row type="flex" justify="center" v-if="item_trip.StayLishi != ''">
										<div class="change-list">
											<div class="txt">
												<i class="zhuan">转</i>
												<span class="fb">{{ item_trip.StayCityName }}</span>
												中转
												<span class="ml15">停留：{{ item_trip.StayLishi }}</span>
												<div class="transferbtnbox explainbox" v-if="item.StayNeedGQ">
													<a class="transferbtn">需转机签</a>
													<div class="explain-innerbox">
														<em class="tri1">◆</em>
														<em class="tri2">◆</em>
														<div class="innerbox">
															1. 中国护照旅客在美国，加拿大，新西兰，澳大利亚，英国等国家转机；
															<br />
															2. 在申根国家发生两次转机的情况；
															<br />
															3. 第三国同一城市两个机场转机的情况；
															<br />
															4. 其他国家边防移民局临时决定的情况；
															<br />
															5. 为确保您的旅行顺畅，请您在出发前需要及时了解有关转机国的最新信息
															<br />
														</div>
													</div>
												</div>
											</div>
										</div>
									</el-row>
								</block>

								<div class="privilege-info" v-show="false">
									<div class="info-tit">特惠搭配</div>
									<div>
										<span>目的地推荐</span>
										<span class="ft12">【机场贵宾室服务】北京首都国际机场贵宾室</span>
										<el-dropdown>
											<span class="el-dropdown-link"><span class="ft12 red">详情</span></span>
											<el-dropdown-menu slot="dropdown" style="width: 200px;">
												<el-dropdown-item>详情</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</div>
							</div>
						</div>
						<!--酒店推荐-->
						<div class="item-b groombox" v-if="index == 5 && TuijianHotel != null">
							<el-row type="flex" justify="space-between" :gutter="24" align="middle">
								<el-col :span="20">
									<div class="flex-start">
										<div class="imgbox"><img :src="TuijianHotel.DistrictImgUrl" alt="Alternate Text"
												style="width:180px;height:100px" /></div>
										<div class="text">为您精选 {{ TuijianHotel.DistrictName }}
											{{ TuijianHotel.HotelCount }} 家超值酒店
										</div>
									</div>
								</el-col>
								<el-col :span="4" class="position-r">
									<el-button class="search-hotel" @click="bindSearchHotel(TuijianHotel)">搜索酒店
									</el-button>
									<i class="close icon-round-close icon-roundclose iconfont"
										@click="TuijianHotel=null"></i>
								</el-col>
							</el-row>
						</div>
					</block>

					<div class="mt20 pb20 text-c">
						<el-row type="flex" justify="center">
							<FlightNoData v-if="isNoData"></FlightNoData>
							<!-- <FlightLoading v-if="isShowFlightLoading" type="航班"></FlightLoading> -->
						</el-row>
					</div>
					<!--分页-->
					<div class="mt20 pb20 text-c" v-if="FlightRouting.length > 0">
						<pagination @handleCurrentChange="handleCurrentChange" :pageTotal="pageCount"
							:page-size="pageSize"></pagination>
					</div>
				</el-col>
			</el-row>
		</div>

		<el-dialog class="des-modal" :visible.sync="showGongGao" :width="widthNum" show-close="true" title="紧急公告" left>
			<div>
				<div style="height:400px;overflow-y: auto;">
					<div v-html="GetGongGaoInfo.Info_Content"></div>
				</div>

				<div class="text-c" style="padding: 20px 0px 10px 0px;">
					<el-button style="width: 120px;" @click="clickDel" v-if="delShow == true">取消</el-button>
					<el-button type="danger" class="button mt25" style="width: 120px;" @click="showGongGao=false">确定
					</el-button>
				</div>
			</div>
		</el-dialog>


		<!-- 控制显示隐藏  ComfirmTicket-->
		<desModal :titleRight="ComfirmTicket" :footerNone="false" :showClose="false">
			<div class="text-c">
				<img src="../../assets/serch_loading.gif" class="img" style="width: 60px;position:relative;top:4px;" />
				<p class="ft16 fn mt20 pb20">正在验证机票，此过程需要几秒钟时间...</p>
			</div>
		</desModal>
	</div>
</template>

<script>
	import intFlightSearch from './components/intFlightSearch.vue';
	import desModal from '../../components/desModal.vue';
	import FlightNoData from '../../components/flightNoData.vue';
	import FlightLoading from '../../components/flightLoading.vue';
	import pagination from '../../components/pagination.vue';
	import VueSuperSlide from 'vue-superslide';
	var util = require('@/utils/util.js');
	var localdatas = require('@/utils/localdatas.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');

	var flightApi = require('@/api/flightApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	var jobid = '';

	export default {
		components: {
			desModal,
			intFlightSearch,
			FlightNoData,
			// FlightLoading,
			pagination
		},
		data() {
			return {
				showGongGao: false,
				checkList: ['选中且禁用', '复选框 A'],
				showAll: false,
				showCity: false,
				TicketSearchInfo: staticData.TicketSearchInfo,
				BookFlightRouting: [], //预订的航班列表
				FlightRouting: [],
				TuijianHotel: null,
				FilterFlightInfo: null,
				filterIndex: 0,
				SortType: 0,
				searchFlightCount: 0,
				pageCount: 0,
				isNoData: false,
				selectFlightID: '',
				isShowFlightLoading: false,
				isDoneData: false,
				SegRef: 1,
				SegRefCount: 1,
				pageindex: 0,
				pageSize: 20,
				filterObj: {
					airlineStr: '',
					departureDateStr: '',
					arrivalDateStr: '',
					turnaroundCountStr: '',
					isZhifei: true
				},
				taxchecked: true,
				currentItem: '',
				priceInfo: {
					comfirmPrice: 0,
					comfirmTax: 0
				},
				ruleInfoList: '',
				ruleRemark: '',
				ComfirmTicket: false, //验价提醒
				GetGongGaoInfo: '', //公告内容
				widthNum: 100,
				delShow: false,
				checkfilterAirines: [],
				checkfilterDeparturedates: [],
				checkfilteArrivaldates: [],
				checkfilterTransferCitys: [],
				searchCount: 0,
				options2: {
					titCell: '.hd ul',
					mainCell: '.bd ul',
					autoPlay: false,
					autoPage: true,
					effect: 'left',
					vis: '6',
					scroll:'6'
				},
			};
		},
		created() {
			that = this;
			var options = that.$route.query;
			console.log(options);
			if (!yqfCommon.isNullOrEmpty(options.m_begionCity) && !yqfCommon.isNullOrEmpty(options.m_endCity)) {
				let parm = {
					dual: options.dual,
					m_begionCity: options.m_begionCity,
					m_endCity: options.m_endCity,
					m_startdate: options.m_startdate,
					airline: options.airline,
					cabin: options.cabin,
					sorttype: options.sorttype
				};
				flightApi.GetTicketSearchByUrlParm(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var TicketSearchInfo = result.data.TicketSearchInfo;
						console.log(TicketSearchInfo);
						localdatas.setTicketSearchInfo(TicketSearchInfo);
						that.TicketSearchInfo = TicketSearchInfo;

						if (!TicketSearchInfo.IsChinaCity) {
							// util.redirectTo('/pages/Ticekets/showDomesitcflight/showDomesitcflight');
							// if (that.SegRef == 1) {
							// 	that.inDate = that.ticketSearchInfo.StartDate;
							// } else {
							// 	that.inDate = that.ticketSearchInfo.ReturnDate;
							// }
							getSearchFSJobid();
							GetTuiJianHotelInfo();
						} else {
							that.$router.push({
								path: '/Flight/ShowDomesticFlight'
							});
						}
					} else {
						that.$router.push({
							path: '/Flight'
						});
					}
				});
			} else {
				that.TicketSearchInfo = localdatas.getTicketSearchInfo();
				if (that.TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/ShowDomesticFlight'
					});
				}
				console.log(that.TicketSearchInfo);
				that.SegRefCount = that.TicketSearchInfo.MultFlightCount;
				that.SortType = that.TicketSearchInfo.SortType;
				getSearchFSJobid();

				GetTuiJianHotelInfo();
			}
			$(window).scrollTop("0");
			// GetGongGao();
		},

		methods: {
			//日期格式
			getDataStr(date, str) {
				if (yqfCommon.isNullOrEmpty(date))
					return;
				let _date = datehelper.DateTimeParse(date);
				return _date.toString(str);
			},
			//改变排序方式
			changeSortType: function(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				that.pageindex = 0;
				that.isLoadDone = false; //console.log(e.currentTarget.dataset.id);

				//console.log(id);
				that.SortType = e;
				that.filterIndex = 0;
				if (e == 1) {
					that.FilterFlightInfo.Sorter.Sortcol = 'AdultPrice';
					that.FilterFlightInfo.Sorter.Ascending = that.FilterFlightInfo.Sorter.Ascending;
				} else {
					that.FilterFlightInfo.Sorter.Sortcol = 'TurnaroundCount';
					that.FilterFlightInfo.Sorter.Ascending = that.FilterFlightInfo.Sorter.Ascending;
				}
				getFlightList();
			},
			//排序
			bindSorter: function(filterIndex, sorterCol) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				console.log(sorterCol);
				that.SortType = 3;
				that.FilterFlightInfo.Sorter.Sortcol = sorterCol;
				that.FilterFlightInfo.Sorter.Ascending = !that.FilterFlightInfo.Sorter.Ascending;
				that.filterIndex = filterIndex;
				getFlightList();
			},
			//筛选
			bindSetFilter: function(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				setFilter();
			},
			//重置筛选
			bindResetFilter: function(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				reSetFilter();
				getFlightList();
			},
			clickDelete: function(e) {
				that.showCity = false;
			},
			bindItemClick(e) {
				var thisitem = e.currentTarget.dataset.id;
				var f_index = e.currentTarget.dataset.idx;
				if (that.currentItem != '' && that.currentItem == thisitem) {
					thisitem = '';
				}
				that.currentItem = thisitem;

				// document.querySelector('#flight_' + id + '').scrollIntoView(true);
				// this.$emit('bindItemClick',id,f_index);
			},
			hide() {
				this.showAll = false;
			},
			show() {
				this.showAll = true;
			},
			//是否含税
			bindChageShowTax() {
				that.taxchecked = !that.taxchecked;
			},
			//分页
			handleCurrentChange(page) {
				console.log(`当前页: ${page}`);
				that.pageindex = page - 1;
				getFlightList();
			},
			//搜索bindReSearch
			bindSearchFlight(TicketSearchInfo) {
				if (TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/ShowDomesticFlight'
					});
				} else {
					reSearch();
				}
			},
			//推荐酒店搜索
			bindSearchHotel: function(e) {
				console.log(e);
			},
			//选择航班
			bindItemNext: function(e) {
				var id = e.currentTarget.dataset.id;
				console.log(that.isDoneData);

				if (!that.isDoneData) {
					// util.wxalert('数据未加载完，请加载完后再操作');
					that.$message.error('数据未加载完，请加载完后再操作');
					return;
				}
				console.log(id);
				// var id = id; //console.log(id);
				// uni.showLoading({
				// 	title: '加载中'
				// });
				var segref = that.SegRef;
				var segRefCount = that.SegRefCount;

				flightApi.BookFlight({
						id: id,
						segref: segref
					},
					function(result) {
						if (result.code == 1) {
							getVeriflyFlightV2(id);
						} else if (result.code == -1) {
							that.$confirm('由于您太久操作，信息已过去，是否重新查询？', '确认信息', {
									distinguishCancelAndClose: true,
									confirmButtonText: '是',
									cancelButtonText: '放弃'
								})
								.then(() => {
									reSearch();
								})
								.catch(action => {});
						} else {
							// console.log(result);;
							that.selectFlightID = id;
							var BookFlightRouting = JSON.parse(result.data.BookFlightRouting);
							that.pageCount = 0;
							that.pageindex = 0;
							that.SegRef = that.SegRef + 1;
							that.BookFlightRouting = BookFlightRouting;
							that.currentItem = '';
							that.FlightRouting = [];
							getFlightList();
							getFilterIntFlightInfo();
						}
					}
				);
			}
		}
	};
	//筛选
	function setFilter() {
		that.pageindex = 0;
		//航司

		var checkfilterAirines = that.checkfilterAirines;

		// console.log(filterDeparturedates);
		var filterAirines = that.FilterFlightInfo.filterAirines;
		for (var i = 0; i < filterAirines.length; i++) {
			if (checkfilterAirines.includes(filterAirines[i].filterInfo.Code)) {
				filterAirines[i].filterInfo.IsChecked = true;
			} else {
				filterAirines[i].filterInfo.IsChecked = false;
			}
		}
		that.FilterFlightInfo.filterAirines = filterAirines;
		console.log(filterAirines);

		//出发时间
		var checkfilterDeparturedates = that.checkfilterDeparturedates;

		var filterDeparturedates = that.FilterFlightInfo.filterDeparturedates;
		// console.log(filterDeparturedates);
		for (var i = 0; i < filterDeparturedates.length; i++) {
			if (checkfilterDeparturedates.includes(filterDeparturedates[i].filterInfo.Code)) {
				filterDeparturedates[i].filterInfo.IsChecked = true;
			} else {
				filterDeparturedates[i].filterInfo.IsChecked = false;
			}
		}
		console.log(filterDeparturedates);
		that.FilterFlightInfo.filterDeparturedates = filterDeparturedates;

		//抵达时间
		var checkfilteArrivaldates = that.checkfilteArrivaldates;

		var filteArrivaldates = that.FilterFlightInfo.filteArrivaldates;

		// console.log(filterDeparturedates);
		for (var i = 0; i < filteArrivaldates.length; i++) {
			if (checkfilteArrivaldates.includes(filteArrivaldates[i].filterInfo.Code)) {
				filteArrivaldates[i].filterInfo.IsChecked = true;
			} else {
				filteArrivaldates[i].filterInfo.IsChecked = false;
			}
		}
		console.log(filteArrivaldates);
		that.FilterFlightInfo.filteArrivaldates = filteArrivaldates;

		//中转城市
		var checkfilterTransferCitys = that.checkfilterTransferCitys;

		var filterTransferCitys = that.FilterFlightInfo.filterTransferCitys;

		// console.log(filterDeparturedates);
		if (filterTransferCitys != null) {
			for (var i = 0; i < filterTransferCitys.length; i++) {
				if (checkfilterTransferCitys.includes(filterTransferCitys[i].filterInfo.Code)) {
					filterTransferCitys[i].filterInfo.IsChecked = true;
				} else {
					filterTransferCitys[i].filterInfo.IsChecked = false;
				}
			}
			console.log(filterTransferCitys);
			that.FilterFlightInfo.filterTransferCitys = filterTransferCitys;
		}

		getFlightList();
	}
	//重置筛选数据
	function reSetFilter() {
		that.pageindex = 0;
		// that.IsZhifei = false;
		// that.fliterselected = false;
		// that.selectAllFlag = false;

		if (that.FilterFlightInfo != null) {
			var FilterFlightInfo = that.FilterFlightInfo;

			for (var i = 0; i < FilterFlightInfo.filterAirines.length; i++) {
				FilterFlightInfo.filterAirines[i].filterInfo.IsChecked = false;
			}
			for (var i = 0; i < FilterFlightInfo.filterDeparturedates.length; i++) {
				FilterFlightInfo.filterDeparturedates[i].filterInfo.IsChecked = false;
			}
			for (var i = 0; i < FilterFlightInfo.filteArrivaldates.length; i++) {
				FilterFlightInfo.filteArrivaldates[i].filterInfo.IsChecked = false;
			}
			if (FilterFlightInfo.filterTransferCitys != null) {
				for (var i = 0; i < FilterFlightInfo.filterTransferCitys.length; i++) {
					FilterFlightInfo.filterTransferCitys[i].filterInfo.IsChecked = false;
				}
			}
			that.FilterFlightInfo = FilterFlightInfo;
		}
		that.checkfilterDeparturedates = [];
		that.checkfilteArrivaldates = [];
		that.checkfilterAirines = [];
		that.checkfilterTransferCitys = []
	}
	//重新搜索
	function reSearch() {
		that.selectFlightID = '';
		that.pageCount = 0;
		that.pageindex = 0;
		that.SegRef = 1;
		that.FlightRouting = [];
		that.BookFlightRouting = [];
		that.FilterFlightInfo = null;
		that.isNoData = false;

		that.TicketSearchInfo = localdatas.getTicketSearchInfo();

		getSearchFSJobid();
		GetTuiJianHotelInfo();
	}
	//查询机票
	function getSearchFSJobid() {
		that.searchCount = 0;
		that.isShowFlightLoading = true;
		that.FlightRouting = [];
		flightApi.GetSearchFSJobid({
				isus: true,
				ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
			},
			function(result) {
				// console.log(result);;
				if (result.code != 0) {
					// uni.hideLoading();
					// (that.isNoData = true), (that.isShowFlightLoading = false);
					that.isNoData = true;
					that.isDoneData = true;
					that.isShowFlightLoading = false;
					return;
				} else {
					jobid = result.data.jobid; //写入统计
					GetGongGao();
					// try {
					// 	var MQuerydate = JSON.parse(result.data.MQueryJson);
					// 	var trackPostData = {
					// 		AccessModeID: 2,
					// 		AirTicket: {
					// 			MQuery: MQuerydate
					// 		}
					// 	};
					// 	util.getYqfTrack(trackPostData);
					// } catch (e) {}

					getFlightListByJobid();
				}
			}
		);
	}

	//根据JOBID获取航班列表数据
	function getFlightListByJobid() {
		console.log(jobid);
		if (jobid == '') {
			console.log('jobid为空');
			return;
		}
		that.searchCount++;
		console.log(that.searchCount);
		let parm = {
			jobid: jobid,
			searchCount: that.searchCount,
			SortType: that.SortType,
			isjson: true,
			ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
		};
		console.log(parm);

		//发送请求
		flightApi.GetFlightDataByJobid(parm, function(result) {
			// console.log(result);;
			let searchFlightCount = that.searchFlightCount;
			let isComplete = false;

			var FlightRoutingList = null;
			if (result.code == 0) {
				isComplete = result.data.isComplete;
				searchFlightCount += result.data.datacount;
				var FlightRoutingList = result.data.FlightRouting;
				that.pageCount = result.data.pageCount;

				if (FlightRoutingList != null && FlightRoutingList.length > 0) {
					// that.isShowFlightLoading = false;
					that.FlightRouting = FlightRoutingList;
				}
			}
			if (!isComplete) {
				setTimeout(function() {
					getFlightListByJobid(jobid);
				}, 1500);
			} else {
				that.isShowFlightLoading = false;
				if (searchFlightCount == 0 || that.FlightRouting.length == 0) {
					that.isNoData = true;
				} else {
					getFilterIntFlightInfo();
				}
			}
			that.isDoneData = isComplete;
			that.searchFlightCount = searchFlightCount;
		});
	}
	//获取航班列表数据
	function getFlightList() {
		let isNoData = false;
		console.log(that.pageindex);
		that.isNoMoreData = false;
		// if (that.isLoading == false) {
		// 	uni.showLoading({
		// 		title: '加载中'
		// 	});
		// }

		let parm = {
			SegRef: that.SegRef,
			SortType: that.SortType,
			pageindex: that.pageindex,
			id: that.selectFlightID,

			FilterFlightInfo: JSON.stringify(that.FilterFlightInfo)
		};
		flightApi.GetFlightList(parm, function(result) {
			if (result.code == 0) {
				that.isLoadDone = result.data.isDone;
				var FlightRoutingList = [];
				if (result.data.FlightRouting != null) {
					that.pageCount = result.data.pageCount;
					FlightRoutingList = JSON.parse(result.data.FlightRouting);
				}
				// if (pageindex > 0 && FlightRoutingList.length == 0) {
				// 	return;
				// }

				if (FlightRoutingList.length == 0) {
					isNoData = true;
				} else {
					isNoData = false;
				}
				that.FlightRouting = FlightRoutingList;
				that.isNoData = isNoData;
			} else if (result.code == -1) {
				that.$alert(result.msg, {
					confirmButtonText: '确定',
					callback: action => {
						reSearch();
					}
				});
			} else {
				console.log(result.msg);
				that.isNoData = true;
				that.FlightRouting = [];
			}
			that.isLoading = false;
		});
	}
	//删除预订的航班信息
	function getRemoveBookFlight() {
		flightApi.RemoveBookFlight(null, function(result) {
			selectFlightID = '';
			pageindex = 0;
			fliterAirlineStr = '';
			fliterDepartureDateStr = '';
			fliterArrivalDateStr = '';
			fliteTurnaroundCountStr = '';
			(that.IsZhifei = false), (that.BookFlightRouting = []), (that.SegRef = 1);

			getFlightList();
			getFilterIntFlightInfo();
		});
	}
	//获取筛选的数据
	function getFilterIntFlightInfo() {
		flightApi.GetFilterFlightInfo({
				SegRef: that.SegRef
			},
			function(result) {
				// console.log(result);;
				// var FilterFlightInfo = JSON.parse(result.data); //console.log(fliterAirlineInfo);
				if (result.code == 0) {
					that.FilterFlightInfo = result.data.flightFilterInfo;
				}
			}
		);
	}

	//验价
	function getVeriflyFlightV2(id) {
		that.ComfirmTicket = true;
		flightApi.GetVeriflyFlightV2({
				id: id
				// companyCode: companyCode
			},
			function(result) {
				that.ComfirmTicket = false;
				if (result.code == 0) {
					//写入统计
					// try {
					// 	var MCheckPrice = JSON.parse(result.data.MCheckPrice);
					// 	var trackPostData = {
					// 		AccessModeID: 3,
					// 		AirTicket: {
					// 			MCheckPrice: MCheckPrice
					// 		}
					// 	};
					// 	util.getYqfTrack(trackPostData);
					// } catch (e) {}
					let tips = '验价后的价格是票价：' + result.data.AdultPrice + '，税费：' + result.data.AdultTax + ',是否继续下单？';
					that.$confirm(tips, {
							distinguishCancelAndClose: true,
							confirmButtonText: '继续预订',
							cancelButtonText: '重新选择'
						})
						.then(() => {
							that.$router.push({
								path: '/Flight/Confirm',
								query: {
									id: id
								}
							});
						})
						.catch(action => {
							reSearch();
							// that.$router.push({ path: '/IntlAir/booking', query: { id: id } });
						});
				} else if (result.code == -2) {
					that.$confirm(result.msg, {
						distinguishCancelAndClose: true,
						confirmButtonText: '重新选择'
						// cancelButtonText: '继续预订'
					}).then(() => {
						reSearch();
					});
					// .catch(action => {
					// 	that.$router.push({ path: '/IntlAir/booking', query: { id: id } });
					// });
				} else {
					that.$confirm(result.msg, {
						distinguishCancelAndClose: true,
						// confirmButtonText: '继续预订',
						confirmButtonText: '重新选择'
					}).then(() => {
						// that.$router.push({ path: '/IntlAir/booking', query: { id: id } });
						reSearch();
					});
					// .catch(action => {
					// 	reSearch();
					// 	// that.$router.push({ path: '/IntlAir/booking', query: { id: id } });
					// });

					// that.$alert(result.msg, {
					// 	confirmButtonText: '确定',
					// 	callback: action => {
					// 		reSearch();
					// 	}
					// });
				}
			}
		);
	}

	function getFareRuleInfo(flightID) {
		var parm = {
			id: flightID
		};
		flightApi.GetFareRuleInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.ruleInfoList = result.data.ruleInfoList;
				that.ruleRemark = result.data.ruleRemark;
			} else {
				that.$confirm(result.msg, '提示', {
					distinguishCancelAndClose: true,
					confirmButtonText: '确定'
				}).then(() => {
					that.showLimitCondition = false;
				});
			}
		});
	}
	//推荐酒店
	function GetTuiJianHotelInfo() {
		var parm = {
			ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
		};
		flightApi.GetTuiJianHotelInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.TuijianHotel = result.data.TuijianHotel;
			}
		});
	}
	//获取公告
	function GetGongGao() {
		// yqfApi.GetGongGao({
		// 		cityCode: that.TicketSearchInfo.ArrivalCityCode,
		// 		country_Code: that.TicketSearchInfo.ArrivalCountryCode,
		// 		iswebsite: true,
		// 	},
		// 	function(result) {
		// 		// console.log(result);;
		// 		// var FilterFlightInfo = JSON.parse(result.data); //console.log(fliterAirlineInfo);
		// 		if (result.code == 0) {
		// 			that.GetGongGaoInfo = JSON.parse(result.data.AdvisorInfo);
		// 			that.showGongGao = that.GetGongGaoInfo != null;
		// 		}
		// 	}
		// );
	}
</script>

<style lang="scss">
	@import '../../style/Flight/flight.scss';
</style>
