<template>
	<div>
		<div class="searchbox" id="searchPanel" style="padding:10px">
			<div class="product-type">
				<div class="item-inp">
					<el-select v-model="TicketSearchInfo.Dual" placeholder="请选择">
						<el-option v-for="item in Duals" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<!--多航段-->
			<div class="ticket-info" v-if="TicketSearchInfo.Dual == 2">
				<div class="info-list" v-for="(item, index) in TicketSearchInfo.MultSegments" :key="index" style="width:initial;">
					<div class="fl" style="margin-top: 10px;margin-right:10px;">
						<span class="duo-num">{{ index + 1 }}</span>
					</div>
					<div class="item-inp city2" style="width:206px;margin-right:20px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">出发城市</span>
							<div class="input-text" id="scrollCity'+index">
								<el-input
									placeholder="中文/英文/首字母"
									clearable
									suffix-icon="iconfont icon-xiangxia2"
									v-model="item.DepartInputVal"
									@focus="bindShowCity($event, index, 0)"
									@input="searchInput"
									@click.stop.native
									style="width:145px"
								></el-input>
							</div>
						</el-row>
					</div>

					<div class="item-inp city2" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">到达城市</span>
							<div class="input-text" id="scrollCity'+index">
								<el-input
									placeholder="中文/英文/首字母"
									clearable
									suffix-icon="iconfont icon-xiangxia2"
									v-model="item.ArrivalInputVal"
									@focus="bindShowCity($event, index, 1)"
									@input="searchInput"
									@click.stop.native
									style="width:145px"
								></el-input>
							</div>
						</el-row>
					</div>
					<div class="item-inp date" style="width:206px;margin-right:20px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">出发日期</span>

							<div class="input-text">
								<!-- <el-input placeholder="yyyy-MM-dd" clearable suffix-icon="iconfont icon-yqfrili" :value="item.DepartDate"></el-input>-->
								<el-date-picker
									value-format="yyyy-MM-dd"
									type="date"
									placeholder="yyyy-MM-dd"
									v-model="item.DepartDate"
									:clearable="false"
									size="large"
									suffix-icon="iconfont icon-yqfrili"
									:picker-options="pickerOptions0"
									style="width: 146px;"
								></el-date-picker>
							</div>
						</el-row>
					</div>

					<span class="del iconfont icon-roundclose" @click="bindDelSegment(index)" style="color: #3acbcb;"></span>
				</div>
				<span class="add-leg-search2" v-if="TicketSearchInfo.Dual == 2" @click="bindAddSegment">添加航段</span>
				<el-row type="flex" align="middle" class="mt10">
					<div class="item-inp city2" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">舱位等级</span>
							<div class="input-text">
								<el-select v-model="TicketSearchInfo.Cabin" placeholder="请选择" style="width:145px">
									<el-option v-for="item in canbins" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</div>
						</el-row>
					</div>

					<div class="gray9 ml10" @click="open(1)">
						<span class="iconfont red icon-hint mr5 ft14 icon-chalv-tishi i-middle"></span>
						<span class="i-middle">全程1对1专人服务及每航段均已含延误赔偿高达¥1600现金券</span>
					</div>
				</el-row>
			</div>
			<div class="ticket-info" v-else>
				<div class="info-list" style="width:initial;">
					<div class="item-inp city2" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">出发城市</span>
							<div class="input-text" id="scrollCity">
								<el-input
									placeholder="中文/英文/首字母"
									:clearable="false"
									suffix-icon="iconfont icon-xiangxia2"
									v-model="TicketSearchInfo.DepartInputVal"
									@focus="bindShowCity($event, 0, 0)"
									@input="searchInput"
									@click.stop.native
									style="width:145px"
								></el-input>
							</div>
						</el-row>
					</div>
					<div class="translate int-translate" @click="bindQieHuan(0)"></div>

					<div class="item-inp city2" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">到达城市</span>
							<div class="input-text" id="scrollCity">
								<el-input
									placeholder="中文/英文/首字母"
									:clearable="false"
									suffix-icon="iconfont icon-xiangxia2"
									v-model="TicketSearchInfo.ArrivalInputVal"
									@focus="bindShowCity($event, 0, 1)"
									@input="searchInput"
									@click.stop.native
									style="width:145px"
								></el-input>
							</div>
						</el-row>
					</div>

					<div class="item-inp date" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">出发日期</span>
							<div class="input-text">
								<!-- <el-input placeholder="yyyy-MM-dd" clearable suffix-icon="iconfont icon-yqfrili" :value="TicketSearchInfo.StartDate"></el-input> -->
								<el-date-picker
									value-format="yyyy-MM-dd"
									type="date"
									placeholder="yyyy-MM-dd"
									v-model="TicketSearchInfo.StartDate"
									:clearable="false"
									size="large"
									suffix-icon="iconfont icon-yqfrili"
									:picker-options="pickerOptions0"
									style="width: 145px;margin-right:5px;"
								></el-date-picker>
							</div>
						</el-row>
					</div>

					<div class="item-inp date backdate" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">到达日期</span>
							<div class="input-text">
								<el-date-picker
									:disabled="TicketSearchInfo.Dual == 0"
									value-format="yyyy-MM-dd"
									type="date"
									placeholder="yyyy-MM-dd"
									v-model="TicketSearchInfo.ReturnDate"
									:clearable="false"
									size="large"
									suffix-icon="iconfont icon-yqfrili"
									:picker-options="pickerOptions0"
									style="width: 145px;"
								></el-date-picker>
							</div>
						</el-row>
					</div>
				</div>
				<el-row type="flex" align="middle">
					<div class="item-inp city2" style="width:206px;">
						<el-row type="flex" align="middle" justify="space-between">
							<span class="gray9 ft12">舱位等级</span>
							<div class="input-text">
								<el-select v-model="TicketSearchInfo.Cabin" placeholder="请选择" style="width:145px">
									<el-option v-for="item in canbins" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</div>
						</el-row>
					</div>
					<div class="gray9 ml10" @click="open(1)">
						<span class="iconfont red icon-hint mr5 ft14 icon-chalv-tishi i-middle"></span>
						<span class="i-middle">全程1对1专人服务及每航段均已含延误赔偿高达¥1600现金券</span>
					</div>
				</el-row>
			</div>

			<div class="rightbox"><el-button type="danger" icon="el-icon-search" class="mt5" @click="bindSearch">重新搜索</el-button></div>
		</div>
		<NewCity
			:showCity="showCity1"
			ref="cityAssembly"
			@clickCityDel="clickCityDel"
			@bindSelectCity="bindSelectCity"
			hotcitytype="plane"
			:top="y"
			:left="x"
			@click.stop.native
		></NewCity>
		<!-- <calendar :showCalender="showDate" @closeCalener="closeCalener" @bindSelectDate="bindSelectDate"></calendar> -->

		<desModal :titleRight="openModal == 1" title="航班延误" class="con-modal"><div style="height:400px">内容</div></desModal>
	</div>
</template>

<script>
import city from '@/components/city/city.vue';
import calendar from '@/components/calendar/calendar.vue';
import desModal from '@/components/desModal.vue';
import { getTicketSearchInfo, setTicketSearchInfo } from '@/utils/localdatas';
import NewCity from '@/components/newCity/newCity.vue';
import { duals, canbins, adults, childrens, TicketSearchInfo } from '@/data/staticData';

var ticketSearchHelper = require('@/utils/ticketSearchHelper.js');

var destApi = require('@/api/destApi.js');
var flightApi = require('@/api/flightApi.js');

var datehelper = require('@/utils/datehelper.js');
var util = require('@/utils/util.js');

var that;
var index = 0;
export default {
	name: 'intFlightSearch',
	props: ['showcity','TicketSearchInfo'],
	components: {
		NewCity,
		desModal
		// calendar
	},
	data() {
		return {
			id: '',
			showCity1: false,
			showDate: false,
			x: 0,
			y: 0,
			Duals: duals,
			openModal: '',
			canbins: canbins,
			adults: adults,
			childrens: childrens,

			// dualType: '0',
			cityIdx: 0,
			cityType: 0,
			dateIdx: 0,

			// TicketSearchInfo: TicketSearchInfo,
			pickerOptions0: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
			},
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
			}
		};
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		document.addEventListener('click', e => {
			that.showCity1 = false;
		});
	},
	created() {
		that = this;
		var query = that.$route.query;
		console.log(query);
		// that.TicketSearchInfo = ticketSearchHelper.GetTicketSearchInfo();
		// that.dualType=that.TicketSearchInfo.Dual;
		console.log(that.TicketSearchInfo);
		// getSearchFSJobid();
	},
	methods: {
		on2: function() {
			this.showCity1 = false;
		},
		open(e) {
			that.openModal = e;
		},

		handleScroll() {
			try {
			if (that.TicketSearchInfo.Dual == 2) {
				var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
			} else {
				var rect = document.querySelector('#scrollCity').getBoundingClientRect();
			}
			this.y = rect.top;
			} catch (e) {
			
			}
			// console.log(window.pageYOffset + "1");
			// console.log(this.y);
		},
		searchInput(e) {
			console.log(e);
			this.$refs.cityAssembly.searchInput(e);
		},
		//搜索航司
		queryAirlineSearch(queryString, cb) {
			var parm = {
				keyword: queryString
			};
			destApi.GetAirlineList(parm, function(result) {
				console.log(result);
				if (result.code == 0) {
					var airlineList = result.data.airlineList;
					cb(airlineList);
				} else {
					cb([]);
				}
			});
		},
		handleSelect(item) {
			console.log(item);
			// that.airlineVal=item.
			// that.formInline.airine=item.code;
			that.TicketSearchInfo.Airline = item.code;
		},
		//选择城市
		bindShowCity(e, cityIdx, cityType) {
			console.log(cityIdx, cityType);
			that.cityIdx = cityIdx;
			that.cityType = cityType;
			this.showCity1 = true;

			var rect = e.target.getBoundingClientRect();
			this.x = rect.left;
			this.y = rect.top;
			console.log(window.pageYOffset + '1');
			console.log(this.y);
			this.classType = e.target.offsetParent.className;
			e.currentTarget.select();
		},
		clickCityDel() {
			this.showCity1 = false;
		},
		//切换城市
		bindQieHuan(idx) {			
			that.TicketSearchInfo=ticketSearchHelper.QieHuan(that.TicketSearchInfo,idx);
		},
		//选择城市
		bindSelectCity(cityModel) {
			
			that.showCity1 = false;
			
			var cityIdx = that.cityIdx;
			var cityType=that.cityType;
			console.log(cityModel);
			console.log(cityIdx);
			console.log(cityType);
						
			that.TicketSearchInfo=ticketSearchHelper.SelectCity(that.TicketSearchInfo,cityType,cityIdx,cityModel);
		},
		//选择日期
		showCalendar(dateIdx) {
			console.log(dateIdx);
			if (that.TicketSearchInfo.Dual == 0 && dateIdx == 1) {
				return;
			}
			that.dateIdx = dateIdx;
			this.showDate = true;
		},
		closeCalener() {
			this.showDate = false;
		},
		//选择日期
		bindSelectDate(dateModel) {
			that.showDate = false;
			that.TicketSearchInfo = ticketSearchHelper.SelectDate( that.TicketSearchInfo,that.dateIdx,dateModel);
		},
		//添加航段
		bindAddSegment() {			
			that.TicketSearchInfo=ticketSearchHelper.AddSegment(that.TicketSearchInfo);
		},
		bindDelSegment(idx) {			
			that.TicketSearchInfo=ticketSearchHelper.DelSegment(that.TicketSearchInfo,idx);
		},
		//搜索
		bindSearch: function(e) {
			var TicketSearchInfo = that.TicketSearchInfo;
			// TicketSearchInfo.Dual=that.dualType;
			flightApi.GetTicketSearch(
				{
					ticketsearchinfo: JSON.stringify(TicketSearchInfo)
				},
				function(result) {
					console.log(result);
					if (result.code == 0) {
						TicketSearchInfo = result.data.TicketSearchInfo;
						console.log(TicketSearchInfo);
						setTicketSearchInfo(TicketSearchInfo);
						if (TicketSearchInfo.IsChinaCity) {
							that.$router.push({
								path: '/Flight/ShowDomesticFlight'
							});
						} else {
							// that.$router.push({ path: '/IntlAir/list' });
							that.$emit('bindSearchFlight', TicketSearchInfo);
						}
					} else {
						that.$message.error(result.msg);
					}
				}
			);
		}
	}
};
</script>

<style>
.guding {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9;
}
</style>
